<template>
  <div class="employee-feedback list-page">
    <div class="list-box">
      <div class="global-operate-block">
        <div class="title">
          <h1>员工反馈</h1>
          <p>Employee feedback</p>
        </div>
      </div>
      <el-table :data="tableData">
        <el-table-column label="序号" type="index" :index="index => index + 1"></el-table-column>
        <el-table-column prop="content" label="改进建议"></el-table-column>
        <el-table-column prop="submitter" label="提交人" width="120"></el-table-column>
        <el-table-column prop="createTime" label="提交时间" width="160"></el-table-column>
        <!-- <el-table-column label="操作" class-name="operate-col">
          <template #default="{row}">
            <el-button @click="handleDelete(row)" class="del">删除</el-button>
          </template>
        </el-table-column> -->
      </el-table>
      <div class="pagination-box">
        <el-pagination
          @current-change="handleCurrentPage"
          :current-page="pagination.currentPage"
          :page-size="pagination.pageSize"
          layout="prev, pager, next"
          :total="pagination.total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { sys } from '@/api'

export default {
  name: 'EmployeeFeedback',
  data() {
    return {
      tableData: [], // 表格数据
      pagination: { // 分页信息
        currentPage: 1,
        pageSize: 10,
        total: 0
      },
    }
  },
  created() {
    this.loadTableData()
  },
  methods: {
    // 获取列表数据
    async loadTableData() {
      let params = {
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize
      }
      const {code, msg, data = [], total = 0} = await sys.getEmployeeFeedbackList(params)
      if (code === 0) {
        this.tableData = data
        this.pagination.total = total
      } else {
        this.$message.error(msg)
      }
    },
    // 改变当前页
    handleCurrentPage(currentPageNo) {
      this.pagination.currentPage = currentPageNo
      this.loadTableData()
    },
    // 删除
    handleDelete({id}) {
      this.$confirm('此操作将删除该项, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const {code, msg} = await sys.delEmployeeFeedbackList({id})
        if(code === 0) {
          this.$message.success(`删除成功`)
          this.loadTableData()
        } else {
          this.$message.error(msg)
        }
      }).catch(() => {
        console.info('取消删除操作')
      })
    },
  }
}
</script>

<style>

</style>